<template>
  <main class="main">
    <section class="portfolio-area p-80">
      <section class="portfolio">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <section class="portfolio-title">
                <h2>{{$t('portfolio.title')}}</h2>
              </section>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 ">
              <section class="portfolio-img">
                <a href="https://www.vlada.gov.sk" target="_blank" rel="noopener noreferrer">
                  <img src="../assets/img/urad-vlady.png" title="Úrad vlády SR" alt="Úrad vlády SR">  
                </a>    
              </section>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 ">
              <section class="portfolio-img">
                <a href="https://www.mfsr.sk/sk/" target="_blank" rel="noopener noreferrer">
                  <img src="../assets/img/min-fin.png" title="Ministerstvo financií SR" alt="Ministerstvo financií SR">  
                </a>    
              </section>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 ">
              <section class="portfolio-img">
                <a href="https://kamenarstvo-gelkam.sk" target="_blank" rel="noopener noreferrer">
                  <img src="../assets/img/Gelkam.jpg" title="Gelkam" alt="Gelkam">  
                </a>    
              </section>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 ">
              <section class="portfolio-img">
                <a href="https://0euro.sk" target="_blank" rel="noopener noreferrer">
                  <img src="../assets/img/0euro.jpg" title="0euro.sk" alt="0euro.sk">  
                </a>    
              </section>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 ">
              <section class="portfolio-img" >
                <a href="https://vonave-sviecky.sk" target="_blank" rel="noopener noreferrer">
                  <img src="../assets/img/sviecky.jpg" title="Voňavé sviečky" alt="Voňavé sviečky">  
                </a>    
              </section>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import setDocumentTitle from '../util'
export default {
  name: 'Portfolio',
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.portfolio"))
      },
      { immediate: true }
    )
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.portfolio-area{
  @include views-general;
  .portfolio{
    @include views;
    .portfolio-title{
      @include section-title;
    }
    .portfolio-img{
      @include img-settings;
    }
    img{
      filter: grayscale(100%);
      &:hover{
        filter: grayscale(0%);
      }
    }
  }
}

</style>